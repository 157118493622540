import { writable } from 'svelte-local-storage-store';

const LOCAL_STORAGE_KEY = 'wwi_store_v01';
type WWILocalStorage = {
    showCookieBanner: boolean;
    cookiesAllowed: boolean;
    bookmarkedCaseStudies: Array<string>;
};

const initialValue: WWILocalStorage = {
    showCookieBanner: true,
    cookiesAllowed: false,
    bookmarkedCaseStudies: [],
};

// First param `preferences` is the local storage key.
// Second param is the initial value.
export const localStore = writable(LOCAL_STORAGE_KEY, initialValue);
